import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

function SectionTitle({ titleKey }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex gap-1 h-10">
        <h3 className="text-xl md:text-2xl font-semibold">{t(titleKey)}</h3>
      </div>
      <div className="bg-[#a7dd7b] h-1 w-[18%] md:w-[5%] mb-10"></div>
    </div>
  );
}

function NetworkCard({ image, descriptionKey, link }) {
  const { t } = useTranslation();
  const content = (
    <>
      <img
        src={`/images/${image}`}
        alt={t(descriptionKey)}
        className="w-32 h-16 md:w-48 md:h-24 object-contain mb-4"
      />
      <p className="text-center text-sm md:text-base font-medium text-gray-700">
        {t(descriptionKey)}
      </p>
    </>
  );

  return (
    <div className="flex flex-col items-center bg-white rounded-lg shadow-md p-6">
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full h-full flex flex-col items-center"
        >
          {content}
        </a>
      ) : (
        content
      )}
    </div>
  );
}

function Partner() {
  const { t } = useTranslation();

  const partners = [
    "meritz",
    "BITC",
    "BITC2",
    "akamai",
    "groundX",
    "greever",
    "bspace",
    "nest",
    "dawin",
    "uppsala",
  ];

  const networks = [
    {
      image: "network_BIFC.png",
      descriptionKey: "networkBIFC",
    },
    {
      image: "network_GRID.png",
      descriptionKey: "networkGRID",
    },
    {
      image: "network_PRENET.png",
      descriptionKey: "networkPRENET",
      link: "https://console.prenet.blockchainbusan.kr/auth/login",
    },
  ];

  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const checkScroll = () => {
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
        scrollContainer.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(checkScroll, 50);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="w-full px-4 pt-20 md:pb-32 md:pt-36 " id="partners">
      <div className="max-w-[1280px] mx-auto w-[96%]">
        <SectionTitle titleKey="partnerIntro" />
        <div className="flex flex-wrap items-center text-left">
          <p className="text-sm sm:text-base md:text-lg">
            {t("partnerDescription", { company: t("retrust") })}
          </p>
        </div>

        <div className="w-full overflow-hidden mt-10">
          <div
            ref={scrollRef}
            className="flex animate-scroll"
            style={{
              width: `${partners.length * 2 * 192}px`,
            }}
          >
            {[...partners, ...partners].map((partner, index) => (
              <img
                key={`${partner}-${index}`}
                src={`/images/partner_${partner}.png`}
                alt={partner}
                className="w-20 h-10 md:w-40 md:h-40 object-contain mx-4"
              />
            ))}
          </div>
        </div>

        <div className="mt-20">
          <SectionTitle titleKey="participatingNetwork" />
          <div className="flex flex-wrap items-center text-left">
            <p className="text-sm sm:text-base md:text-lg">
              {t("networkDescription", { company: t("retrust") })}
            </p>
          </div>
        </div>

        <div className="mt-10 pb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {networks.map((network, index) => (
              <NetworkCard
                key={index}
                image={network.image}
                descriptionKey={network.descriptionKey}
                link={network.link}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
