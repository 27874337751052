import React from "react";
import { useTranslation } from "react-i18next";

function AkamaiMerit() {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full">
        <div
          className="max-w-[1280px] mx-auto md:pt-36 md:pb-20 px-4"
          id="merit"
        >
          <h3 className="text-lg md:text-3xl font-bold">
            {t("differentiation")}
          </h3>
          <img
            src="/images/akamaigraph.png"
            alt={t("akamaiGraphAlt")}
            className="w-full h-auto mt-4 md:mt-8"
          />
        </div>
      </div>
    </>
  );
}

export default AkamaiMerit;
