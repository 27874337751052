import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Mmenu from "../assets/mobileMenu.svg";
import close from "../assets/close.svg";
import { useMenuToggle } from "../hook/useMenuToggle";
import { useLanguageChange } from "../hook/useLanguageChange";

function AkamaiNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMenuOpen, toggleMenu, closeMenu } = useMenuToggle();
  const changeLanguage = useLanguageChange();

  const homeBtn = () => {
    navigate("/");
  };

  const menuItems = [
    { name: t("blockChain"), link: "/blockChain" },
    { name: t("newStandard"), id: "select" },
    { name: t("uniqueness"), id: "merit" },
    { name: t("planInfo"), id: "plan" },
  ];

  const smoothScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleNavClick = (item) => {
    closeMenu();
    if (item.link) {
      navigate(item.link);
    } else if (item.id) {
      smoothScrollTo(item.id);
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 w-full bg-white shadow-md z-50">
      <div className="max-w-[1280px] mx-auto py-4 px-4 md:px-0 md:py-6">
        <div className="flex flex-col">
          <div className="hidden md:flex md:justify-end md:items-center mr-4">
            <button className="text-xs" onClick={() => changeLanguage("ko")}>
              {t("ko")}
            </button>
            <span className="text-xs px-2">|</span>
            <button className="text-xs" onClick={() => changeLanguage("en")}>
              {t("en")}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div
              className="w-[140px] h-[40px] sm:w-[150px] sm:h-[50px] cursor-pointer"
              onClick={homeBtn}
            >
              <img
                src="/images/RetrustLogo.png"
                alt="logo"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="flex items-center">
              <div className="md:hidden">
                <button onClick={toggleMenu}>
                  <img src={Mmenu} alt="mobile menu" className="w-6 h-6" />
                </button>
              </div>
            </div>

            <nav
              className={`
            fixed top-0 left-0 h-full bg-white w-full  shadow-lg z-[200]
            transform transition-transform duration-300 ease-in-out
            ${isMenuOpen ? "translate-x-0" : "-translate-x-full"}
            md:relative md:transform-none md:shadow-none md:w-auto md:bg-transparent md:z-auto
          `}
            >
              <div className="pt-10 px-8 mmb:p-0 ">
                <div className="flex justify-end w-full mb-10 mmb:hidden">
                  <img src={close} alt="" width={20} onClick={closeMenu} />
                </div>
                <ul className="flex flex-col gap-6 md:flex-row md:items-center md:p-0">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className="my-2 md:my-0 md:mx-2 text-xl md:text-base"
                    >
                      <a
                        href={item.link ? item.link : `#${item.id}`}
                        className="relative inline-block text-black md:text-black
                                 after:content-[''] after:absolute after:w-0 after:h-0.5 
                                 after:bg-[#387936] after:left-1/2 after:-bottom-1
                                 after:transition-all after:duration-300 
                                 hover:after:w-full hover:after:left-0
                                 hover:text-[#386937]"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavClick(item);
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}

                  <li className="md:hidden mt-4 flex">
                    <button
                      className="px-2 py-1 text-sm text-black"
                      onClick={() => changeLanguage("ko")}
                    >
                      {t("ko")}
                    </button>
                    <span>|</span>
                    <button
                      className="px-2 py-1 text-sm text-black"
                      onClick={() => changeLanguage("en")}
                    >
                      {t("en")}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[150] md:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
}

export default AkamaiNav;
