import React from "react";
import { useTranslation } from "react-i18next";

function ClaudeSelect() {
  const { t } = useTranslation();

  const contents = [
    {
      title: t("computingTitle"),
      description: t("computingDesc"),
    },
    {
      title: t("databaseTitle"),
      description: t("databaseDesc"),
    },
    {
      title: t("storageTitle"),
      description: t("storageDesc"),
    },
    {
      title: t("securityTitle"),
      description: t("securityDesc"),
    },
  ];

  return (
    <div className="w-full">
      <div
        className="max-w-[1280px] mx-auto md:pt-36 md:pb-20 px-4"
        id="select"
      >
        <h3 className="text-lg md:text-3xl font-bold mb-10">
          {t("cloudStandardTitle")}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
          {contents.map((content, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 border p-4 rounded-lg"
            >
              <span className="text-sm tracking-tighter md:text-base font-semibold">
                {index + 1}. {content.title}
              </span>
              <p className="text-xs md:text-sm">{content.description}</p>
              <div className="w-full mt-2 aspect-w-16 aspect-h-9 relative">
                <img
                  src={`/images/컴퓨팅${index + 1}-${index + 1}.jpg`}
                  alt={`Computing option ${index + 1}`}
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ClaudeSelect;
