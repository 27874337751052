import Banner from "../components/Banner";
import Mission from "../components/Mission";
import Service from "../components/Service";
import News from "../components/News";
import Partner from "../components/Partner";
import Nav from "../components/Nav";

function Main() {
  return (
    <>
      <Nav />
      <Banner />
      <Mission />
      <Service />
      <News />
      <Partner />
    </>
  );
}

export default Main;
